<template>
	<div class="cardRow">
		<div class="cardRow__header">Счета</div>
		<div class="cardRow__body">
			<div
				class="bonus-card"
				:class="content.color"
				v-for="content in this.data"
				:key="content.name"
			>
				<span class="bonus-card__name">{{ content.name }}</span>
				<div class="bonus-card__price">
					<div class="bonus-card__number">{{ content.price }}</div>
					<div class="bonus-card__currency">рублей</div>
				</div>
				<div class="bonus-card__modal">
					<div
						@click="$emit('pointsOut')"
						class="bonus-card__request"
					>Запрос на вывод
					</div>
				</div>
				<div class="bonus-card__footer">
					<span class="bonus-card__hold">Заморожено:</span>
					<span class="bonus-card__hold-price">{{ content.holdPrice }}</span>
				</div>
				<div class="bonus-card__logo"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['data'],
	data()
	{
		return {}
	}
}
</script>

<style lang="scss">
$black: #3D4248;

.cardRow
{
	margin-bottom: 56px;

	&__header
	{
		color: $black;
		font-weight: 700;
		font-size: 24px;
	}

	&__body
	{
		display: flex;
		flex-wrap: wrap;
	}
}

.bonus-card
{
	padding: 24px;
	border-radius: 24px;
	color: white;
	width: 314px;
	border: 2px solid #F0F0F0;
	position: relative;
	margin-right: 32px;
	margin-top: 32px;

	&_green
	{
		background: linear-gradient(178.06deg, #8ADB90 1.7%, #66BE6D 98.68%);
	}

	&_gold
	{
		background: linear-gradient(178.06deg, #E1CB91 1.7%, #C5B16C 98.68%);
	}

	&_platinum
	{
		background: linear-gradient(178.06deg, #C8C8C8 1.7%, #999999 98.68%);
	}

	&__name
	{
		display: block;
		opacity: 0.6;
		font-size: 14px;
		font-weight: 700;
		margin-bottom: 5px;
	}

	&__number
	{
		font-size: 32px;
		font-weight: 700;
		margin-bottom: 5px;
	}

	&__currency
	{
		font-size: 16px;
		font-weight: 700;
	}

	&__modal
	{
		margin-top: 10px;
		text-align: end;
		margin-bottom: 10px;
		justify-content: end;
		display: grid;
	}

	&__request
	{
		font-size: 16px;
		text-decoration: none;
		border-bottom: 1px solid;
		color: white;
		cursor: pointer;
	}

	&__footer
	{
		display: flex;
		justify-content: space-between;
	}

	&__hold
	{
		opacity: 0.6;
		font-size: 14px;
		font-weight: 700;

		&:before
		{
			content: '';
			background: url("~@/assets/img/question.svg");
			height: 17px;
			width: 17px;
			display: inline-block;
			position: relative;
			top: 3px;
			margin-right: 5px;
		}
	}

	&__logo
	{
		background: url("~@/assets/img/logo.svg");
		background-size: cover;
		position: absolute;
		top: 24px;
		right: 24px;
		height: 41px;
		width: 38px;
	}
}
</style>
